<script setup>
import { onMounted, ref } from "vue";
import { useUserStore } from "../../../stores/user";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import useInboundOrders from "../../../hooks/useInboundOrders";
import OrderHeader from "../../../components/order/OrderHeader.vue";
import InboundOrderLayout from "../../../layouts/InboundOrderLayout.vue";
import OrderAppointmentCard from "@/components/order/OrderAppointmentCard.vue";

const {
  inboundOrder,
  inboundLoadingCount,
  fetchInboundOrder,
  fetchInboundOrderAppointments,
} = useInboundOrders();

const userStore = useUserStore();
const route = useRoute();

const appt = ref({});
const closedAppt = ref(null);
const openApptHistory = ref([]);
const closedApptHistory = ref([]);

onMounted(async () => {
  await fetchInboundOrder(route.params.id).then(() => {
    setPageTitle(`Inbound Order #IN-${inboundOrder.value.id}`);
  });
  appt.value = await fetchInboundOrderAppointments(route.params.id);
});

</script>

<template>
  <InboundOrderLayout :inboundOrder="inboundOrder" v-if="inboundOrder">
    <div class="w-100">
      <div v-if="userStore.user && inboundOrder" class="px-4">
        <div class="w-100 px-2">
          <div class="w-100">
            <OrderHeader
              orderType="inbound"
              :orderId="inboundOrder.id"
              :status="inboundOrder.order_status.short_label"
            />
          </div>

          <v-card v-if="!appt && inboundLoadingCount == 0" class="mt-4 pa-2 w-auto">
            <v-card-title> Appointments </v-card-title>
            <v-card-text>
              <p>No appointments found.</p>
            </v-card-text>
          </v-card>

          <!-- Open Appointments -->
          <OrderAppointmentCard :key="appt" title="Open Appointments" :appt="appt" orderType="inbound" :history="openApptHistory"/>
          <!-- Closed Appointments -->
          <OrderAppointmentCard :key="closedAppt" v-if="closedAppt" title="Appointments" :appt="closedAppt" orderType="inbound" :history="closedApptHistory"/>
          
        </div>
      </div>
    </div>
  </InboundOrderLayout>
</template>

<style scoped>
.history-table, .appt-detail-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

.appt-detail-table tbody tr td, .appt-detail-table tbody tr th {
  border-bottom: 1px solid #B5B5B5 !important;
}

.history-table tbody tr td {
  border-bottom: 1px solid #B5B5B5 !important;
  padding: 0.5rem !important;
}

</style>